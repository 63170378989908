<template>
    <div id="app">
      <PortfolioPage :items="imageData" />
    </div>
  </template>
  
  <script>
  import PortfolioPage from "@/components/brendkit/PortfolioPage.vue";

  export default {
    components: {
      PortfolioPage,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  