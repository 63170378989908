<template>
    <v-container>
        <v-dialog v-model="dialogVisibleForm" max-width="800" >
      <v-card class="py-5">
        <PortfolioForm />
      </v-card>
      
    </v-dialog>
 
        <v-sheet color="#f5f5f5">
            <v-row>
            <v-col cols="3">
              <v-text-field append-inner-icon="mdi-magnify" density="compact" variant="outlined" v-model="searchQuery" label="Pesquisar curso..." outlined @input="performSearch" clearable></v-text-field>
            </v-col>
      <v-col class="d-flex justify-end">
                  <v-btn  prepend-icon="mdi-plus"
                      text="Publicar curso"
                          @click="dialogVisibleForm = true"
                      ></v-btn>
                  </v-col>
    </v-row>
        </v-sheet> 
      <!-- Project List -->
      <v-row>
        <v-col v-for="project in projects" :key="project._id" cols="12" md="4">
          <v-card @click="navigateToDetails(project._id)">
            <v-img v-for="(image, index) in project.coverImage" :key="index" :src="image.path" height="200" cover></v-img>
            <v-card-title>{{ project.productName }}</v-card-title>
            <v-card-subtitle>
              Sections: {{ project.units.length }} | Year: {{ project.duration }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
import PortfolioForm from './PortfolioForm.vue';

  const projects = ref([]);
  const dialogVisibleForm = ref(false);

  const router = useRouter();
  
  const fetchProjects = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/project');
      projects.value = response.data;
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  
  const navigateToDetails = (projectId) => {
    router.push({ name: 'PortfolioDetails', params: { id: projectId } });
  };
  
  onMounted(() => {
    fetchProjects();
  });
  </script>
  
  <style scoped>
  .v-card {
    cursor: pointer;
  }
  </style>
  