<template>
    <div id="app">
      <PhoneProducts :items="imageData" />
    </div>
  </template>
  
  <script>
  import PhoneProducts from "@/components/products/PhoneProducts.vue";


  export default {
    components: {
      PhoneProducts,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  