<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :sort-by="[{ key: 'email', order: 'asc' }]"
  >
  <template v-slot:[`item.portfolio`]="{ item }">
  <div v-for="(file, index) in item.portfolio" :key="index">
    <a :href="file.path" target="_blank">{{ file.name }}</a>
  </div>
</template>

<template v-slot:[`item.abstract`]="{ item }">
  <div v-for="(file, index) in item.abstract" :key="index">
    <a :href="file.path" target="_blank">{{ file.name }}</a>
  </div>
</template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Inout Artist in Residence Submissions</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              class="mb-2"
              color="primary"
              dark
              v-bind="props"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Full name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                   
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      label="email"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                   
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      label="phone"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                   
                  >
                    <v-text-field
                      v-model="editedItem.business"
                      label="business"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                   
                  >
                  <v-text-field
                      v-model="editedItem.country"
                      label="country"
                    ></v-text-field>
                  
                  </v-col>
                  <v-col
                    cols="12"
                   
                  >
                  <v-text-field
                      v-model="editedItem.book"
                      label="Book Launch"
                    ></v-text-field>
                  
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="me-2"
        size="small"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        size="small"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { title: 'Full name', align: 'start', sortable: false, key: 'name' },
      { title: 'Email', key: 'email' },
      { title: 'Phone', key: 'phone' },
      { title: 'Country', key: 'country' },
      { title: 'Links for your music', key: 'links' },         
      { title: 'Art Discipline', key: 'discipline' }, 
      { title: 'Portfolio', key: 'portfolio' },   
      { title: 'Able to cover your travel expenses?', key: 'expenses' },        
      { title: 'Participate in our Global Fundraising', key: 'fundraise' },        
      { title: 'Choose all the disciplines that apply to you?', key: 'disciplines' },        
      { title: 'What is on your mind for the residency', key: 'motivation' },        
      { title: 'proposal', key: 'abstract' },        
      { title: 'Project Description', key: 'brief' },        
      { title: 'newsletter', key: 'newsletter' },         
    
      
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    desserts: [],  // This will be replaced with data fetched from the backend
    editedIndex: -1,
    editedItem: {
      name: '',
      email: '',
      phone: '',
      links: '',
      country: '',
      motivation: '',
      expenses: '',
      portfolio: '',
      abstract: '',
      discipline: '',
      fundraise: '',
      disciplines: '',
      brief: '',
      

     
    },
    defaultItem: {
      name: '',
      email: '',
      phone: '',
      links: '',
      country: '',
      motivation: '',
      expenses: '',
      portfolio: '',
      abstract: '',
      discipline: '',
      fundraise: '',
      disciplines: '',
      brief: '',
  
    },
    formType: 'Inoutart', // Replace with the specific form type you want to fetch
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchData();  // Fetch the data from the backend on component creation
  },

  methods: {
    // Fetch submissions based on specific formType
     // Fetch submissions based on specific formType
     async fetchData() {
      try {
        const response = await axios.get(`https://server.matapa.org/api/festival-submissions?formType=${this.formType}`);
        this.desserts = response.data.map(item => ({
          ...item,
          portfolio: this.formatFileLinks(item.portfolio), // Process portfolio file URLs
          abstract: this.formatFileLinks(item.abstract),   // Process abstract file URLs
          links: this.formatArrayToString(item.links), // Format statement
          disciplines: this.formatArrayToString(item.disciplines), // Format statement
        }));  // Populate the table with processed data
      } catch (error) {
        console.error('Error fetching submissions:', error);
      }
    },

    // Helper function to process file URLs and generate clickable links
    formatFileLinks(files) {
      if (!files || files.length === 0) return [];

      const baseURL = 'https://server.matapa.org';  // Define the correct base URL

      return files.map(file => ({
        name: file.filename || 'Unnamed file',
        path: file.path.startsWith('http') ? file.path : `${baseURL}/${file.path}` // Check if the path is relative
      }));
    },

     // Helper function to convert arrays into comma-separated strings
  formatArrayToString(array) {
    if (!Array.isArray(array) || array.length === 0) return "";
    return array.join(", "); // Join array items with commas
  },
    // Save (Create or Update) a submission
    async save() {
      if (this.editedIndex > -1) {
        // Update existing item
        try {
          await axios.put(`https://server.matapa.org/api/festival-submissions/${this.editedItem._id}`, this.editedItem);
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } catch (error) {
          console.error('Error updating submission:', error);
        }
      } else {
        // Create new item
        try {
          const response = await axios.post('https://server.matapa.org/api/festival-submissions', this.editedItem);
          this.desserts.push(response.data);  // Add the new item to the table
        } catch (error) {
          console.error('Error creating submission:', error);
        }
      }
      this.close();
    },

    // Edit item
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    // Delete item
    async deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    // Confirm delete
    async deleteItemConfirm() {
      try {
        await axios.delete(`https://server.matapa.org/api/festival-submissions/${this.editedItem._id}`);
        this.desserts.splice(this.editedIndex, 1);  // Remove item from the table
      } catch (error) {
        console.error('Error deleting submission:', error);
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>