<template>
    <div id="app">
      <PortfolioDetails :items="imageData" />
    </div>
  </template>
  
  <script>
  import PortfolioDetails from "@/components/brendkit/PortfolioDetails.vue";
  export default {
    components: {
      PortfolioDetails,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  