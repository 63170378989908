<template>
    <Container fluid >
      <!-- v-select for selecting the component -->
       <v-row class="pa-5">
        <v-col cols="12" md="3">
            <v-select
        v-model="model"
        label="Select Form"
        :items="items"
        item-value="value" 
        item-title="label" 
      ></v-select>
        </v-col>
       </v-row>
   
  
      <!-- Dynamically render the selected component -->
      <v-sheet v-if="model" class="pa-5">
        <v-card flat>
          <component :is="model" />
        </v-card>
      </v-sheet>
    </Container>
  </template>
  
  <script>
  import AdvertForm from './AdvertForm.vue';
  import DesignersForm from './DesignersForm.vue';
  import DjForm from './DjForm.vue';
  import MemberForm from './MemberForm.vue';
  import InvestorsForm from './InvestorsForm.vue';
  import DocFilms from './DocfilmsForm.vue';
  import InoutMoya from './InoutMoya.vue';
  import InoutZore from './InoutZore.vue';
  import InoutSonart from './InoutSonart.vue';
  import InoutSerenata from './InoutSerenata.vue';
  import InoutArt from './InoutArt.vue';
  import VolunteersForm from './VolunteersForm.vue';
  
  export default {
    components: {
      AdvertForm,
      DocFilms,
      DesignersForm,
      DjForm,
      InoutArt,
      InvestorsForm,
      MemberForm,
      InoutSerenata,
      InoutSonart,
      InoutZore,
      InoutMoya,
      VolunteersForm,
    },
    data: () => ({
      // Items for the select field
      items: [
        { label: 'Advertisement', value: 'AdvertForm' },   
        { label: 'DJ', value: 'DjForm' },
        { label: 'Become a Member', value: 'MemberForm' },
        { label: 'Vendors  & Investors', value: 'InvestorsForm' },
        { label: 'Fashion Designers', value: 'DesignersForm' },
        { label: 'Documentary Filmmakers', value: 'DocFilms' },
        { label: 'INOUT Amoya', value: 'InoutMoya' },
        { label: 'INOUT Zore', value: 'InoutZore' },
        { label: 'INOUT Sonart', value: 'InoutSonart' },
        { label: 'INOUT Serenata', value: 'InoutSerenata' },
        { label: 'INOUT Art Eco-Scientific', value: 'InoutArt' },
        { label: 'Volunteer with us', value: 'VolunteersForm' },
      ],
      model: null, // Tracks the selected component
    }),
  };
  </script>
  