<template>
  <v-container class="d-flex justify-center align-center" style="min-height: 100vh;">
    <v-card width="400" class="pa-4">
      <v-card-title class="headline text-center">Create an Account</v-card-title>

      <v-form v-model="formValid" @submit.prevent="registerUser">
        <!-- Username Input -->
        <v-text-field
          v-model="username"
          label="Username"
          :rules="[rules.required]"
          required
          append-inner-icon="mdi-account"
        ></v-text-field>

        <!-- Email Input -->
        <v-text-field
          v-model="email"
          label="Email"
          :rules="[rules.required, rules.email]"
          required
          append-inner-icon="mdi-email"
        ></v-text-field>

        <!-- Contact Input -->
        <v-text-field
          v-model="contact"
          label="Contact"
          :rules="[rules.required, rules.contact]"
          required
          append-inner-icon="mdi-phone"
        ></v-text-field>

        <!-- Website Input -->
        <v-text-field
          v-model="website"
          label="Website"
          :rules="[rules.required]"
          required
          append-inner-icon="mdi-web"
        ></v-text-field>

        <!-- Category Input -->
        <v-text-field
          v-model="category"
          label="Category"
          :rules="[rules.required]"
          required
          append-inner-icon="mdi-briefcase"
        ></v-text-field>

        <!-- Password Input -->
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          :rules="[rules.required, rules.password]"
          required
          append-inner-icon="mdi-lock"
        ></v-text-field>

        <!-- Register Button -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid"
          class="mt-4"
          block
        >
          Register
        </v-btn>
      </v-form>

      <v-divider class="my-4"></v-divider>

      <v-row justify="center">
        <v-col>
          <v-btn variant="text" @click="goToLoginPage">Already have an account? Login</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const username = ref('');
    const email = ref('');
    const contact = ref('');
    const website = ref('');
    const category = ref('');
    const password = ref('');
    const formValid = ref(false);

    // Form validation rules
    const rules = {
      required: (value) => !!value || 'This field is required',
      email: (value) => /.+@.+\..+/.test(value) || 'Enter a valid email',
      contact: (value) => /^[0-9]{10}$/.test(value) || 'Enter a valid contact number',
      password: (value) => value.length >= 6 || 'Password must be at least 6 characters',
    };

    // Registration function
    const registerUser = async () => {
      try {
        const response = await axios.post('https://server.matapa.org/api/register', {
          username: username.value,
          email: email.value,
          contact: contact.value,
          website: website.value,
          category: category.value,
          password: password.value,
        });

        if (response.status === 201) {
          alert('Registration successful!');
          router.push('/login'); // Redirect to login page after registration
        }
      } catch (error) {
        console.error('Registration failed:', error.response.data.error);
        alert('Registration failed: ' + error.response.data.error);
      }
    };

    // Navigate to login page
    const goToLoginPage = () => {
      router.push('/login');
    };

    return {
      username,
      email,
      contact,
      website,
      category,
      password,
      formValid,
      rules,
      registerUser,
      goToLoginPage,
    };
  },
};
</script>

<style scoped>
.v-card {
  max-width: 400px;
  margin: auto;
}
</style>
