<template>
    <v-data-table
      :headers="headers"
      :items="users"
      :sort-by="[{ key: 'username', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gestão de usuários</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn class="mb-2" color="primary" dark v-bind="props">
                Novo usuário
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="my-5 ml-5">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container fluid="">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.username" label="Nome"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.contact" label="Contacto"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.category" label="Posição"></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.website" label="Website"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.databases" label="Database"></v-text-field>
                    </v-col> -->
                    <v-col cols="12" md="6">
                      <v-text-field v-model="editedItem.password" label="Password" type="password"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza que pretende apagar este usuário?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancelar</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">Sim</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </template>
<script>
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { title: 'Nome', key: 'username' },
      { title: 'Email', key: 'email' },
      { title: 'Contacto', key: 'contact' },
      { title: 'Posição', key: 'category' },
      // { title: 'Website', key: 'website' },
      // { title: 'Databases', key: 'databases' },
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    users: [],    
    editedIndex: -1,
    editedItem: {
      username: '',
      email: '',
      contact: '',
      category: '',
      // website: '',
      // databases: [],
      password: '',
    },
    defaultItem: {
      username: '',
      email: '',
      contact: '',
      category: '',
      // website: '',
      // databases: [],
      password: '',
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Novo usuário' : 'Editar Usuário';
    },
  },

  watch: {
    dialog (val) {
      val || this.close();
    },
    dialogDelete (val) {
      val || this.closeDelete();
    },
  },

  created () {
    this.initialize();
  },

  methods: {
    async initialize () {
      try {
        const response = await axios.get('https://bdq.brendkit.com/api/users'); // Adjust the endpoint as needed
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },

    editItem (item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm () {
      try {
        await axios.delete(`https://bdq.brendkit.com/api/users/${this.editedItem._id}`); // Adjust the endpoint as needed
        this.users.splice(this.editedIndex, 1);
        this.closeDelete();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },

    close () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save () {
      try {
        if (this.editedIndex > -1) {
          // Update existing user
          await axios.put(`https://bdq.brendkit.com/api/users/${this.editedItem._id}`, this.editedItem); // Adjust the endpoint as needed
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          // Create new user
          const response = await axios.post('https://bdq.brendkit.com/api/users', this.editedItem); // Adjust the endpoint as needed
          this.users.push(response.data);
        }
        this.close();
      } catch (error) {
        console.error('Error saving user:', error);
      }
    },
  },
};
</script>
  