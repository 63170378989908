<template>

        <h4 class="py-8"> Crie categorias para eventos</h4>
        <v-form @submit.prevent="addCategory">
          <v-row>
            <v-col cols="12">
              <v-text-field variant="outlined" density="compact" v-model="newCategory.name" label="Nome de grupo de categoria" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row class="pl-5" v-for="(subcategory, subIndex) in newCategory.subcategories" :key="subIndex" >
                <v-col cols="10">
                  <v-text-field hide-details variant="outlined" density="compact" v-model="subcategory.name" label="adicione a categoria"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn variant="text" size="small" @click="removeNewSubcategory(subIndex)" color="error" text icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
            
              </v-row>
            </v-col>
            <v-col class="pl-4" cols="6">
              <v-btn variant="outlined" size="small" @click="addNewSubcategory" color="primary" icon><v-icon>mdi-plus</v-icon>
                <v-tooltip                
                color="primary"
                activator="parent"
                location="end"
              >Adicionar categoria</v-tooltip>
              </v-btn>
         
            </v-col>
            <v-col cols="12" class="d-flex justify-end">              
              <v-btn class="my-4" type="submit" height="45" border="0" color="success">Salvar Categoria</v-btn>
            </v-col>
          </v-row>
        </v-form>
<v-expansion-panels v-if="categories.length > 0">
  <v-expansion-panel v-for="(category, catIndex) in categories" :key="catIndex">
    <v-expansion-panel-title v-model="category.name">
     <h4>{{ category.name }}</h4>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content>
                <v-text-field variant="solo-filled" v-model="category.name" label="Category Name" :disabled="!category.editing"></v-text-field>
                <v-row class="pl-5" v-for="(subcategory, subIndex) in category.subcategories" :key="subIndex" no-gutters>
                  <v-col cols="10">
                    <v-text-field variant="outlined" density="compact" v-model="subcategory.name" label="Subcategory Name" :disabled="!category.editing"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn v-if="category.editing" variant="text" size="small" @click="deleteSubcategory(category, subIndex)" color="error" text icon>
                      <v-icon>mdi-delete</v-icon>
                      <v-tooltip                
                color="primary"
                activator="parent"
                location="end"
              >Apagar subcategoria</v-tooltip>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="category.editing">
                  <v-col cols="10">
                    <v-text-field variant="outlined" density="compact" v-model="newSubcategory[category._id]" label="New Subcategory Name"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn size="small" @click="addSubcategory(category)" color="primary" text icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="!category.editing" @click="category.editing = true" color="primary" class="mr-5"><v-icon>mdi-pencil</v-icon>Edit</v-btn>
                <v-btn v-else @click="saveCategory(category)" color="success" class="mr-5"><v-icon>mdi-floppy</v-icon>Salvar categoria</v-btn>
                <v-btn variant="outlined" @click="deleteCategory(category)" color="error"><v-icon>mdi-delete</v-icon>Apagar</v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          
        </v-list>
    </v-expansion-panel-text>
 
        
  </v-expansion-panel>
 
</v-expansion-panels>
    
<v-alert v-else>No categories available.</v-alert>

     

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';


const categories = ref([]);
const newCategory = ref({
  name: '',
  subcategories: [{ name: '' }],
});

const newSubcategory = ref({});

const fetchCategories = async () => {
  try {
    const response = await axios.get('https://server.matapa.org/api/categories');
    categories.value = response.data.map(category => ({ ...category, editing: false }));
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
};



const addCategory = async () => {
  try {
    const response = await axios.post('https://server.matapa.org/api/categories', newCategory.value);
    categories.value.push({ ...response.data, editing: false });
    newCategory.value = { name: '', subcategories: [{ name: '' }] };
  } catch (error) {
    console.error('Error adding category:', error);
  }
};



const addNewSubcategory = () => {
  newCategory.value.subcategories.push({ name: '' });
};


const removeNewSubcategory = (subIndex) => {
  newCategory.value.subcategories.splice(subIndex, 1);
};



const addSubcategory = async (category) => {
  if (!newSubcategory.value[category._id]) return;
  category.subcategories.push({ name: newSubcategory.value[category._id] });
  newSubcategory.value[category._id] = '';
};


const saveCategory = async (category) => {
  try {
    await axios.put(`https://server.matapa.org/api/categories/${category._id}`, category);
    category.editing = false;
  } catch (error) {
    console.error('Error saving category:', error);
  }
};




const deleteCategory = async (category) => {
  try {
    await axios.delete(`https://server.matapa.org/api/categories/${category._id}`);
    console.log('Category and subcategories deleted successfully');
    fetchCategories();
  } catch (error) {
    console.error('Error deleting category and subcategories:', error);
  }
};



const deleteSubcategory = (category, subIndex) => {
  category.subcategories.splice(subIndex, 1);
};



onMounted(fetchCategories);

</script>

<style scoped>
</style>
