<template>
    <div class="furniture-form">
      <h2>Publicar proejcto</h2>
      <v-form @submit.prevent="saveProject">
        <div class="cover-image-upload"
             :class="{ 'dragging-over': isDragging }"
             @dragover.prevent="handleDragOver"
             @dragleave.prevent="handleDragLeave"
             @drop.prevent="handleDrop">
          <div v-if="coverPreviewUrl" class="cover-image-preview">
            <img :src="coverPreviewUrl" alt="Cover Image" class="cover-image" />
          </div>
          <div v-if="coverErrorMessage" class="error-message">{{ coverErrorMessage }}</div>
        </div>
        <p class="mb-5 text-caption" color="indigo-lighten-3">A imagem deve ter de largura e altura no mínimo: 744px por 209px</p>
        <div class="upload-actions mx-auto mb-20">
          <v-btn variant="outlined" flat block @click="triggerCoverFileInput" color="primary">Carregar imagem de capa</v-btn>
          <input type="file" ref="coverFileInput" @change="handleCoverFileChange" style="display: none" accept="image/*" />
          <v-progress-linear v-if="coverUploadProgress > 0" :value="coverUploadProgress" striped color="blue"></v-progress-linear>
        </div>
        <div class="py-4"></div>
        <v-text-field v-model="productName" label="Nome do projecto" required></v-text-field>
        
  
        <v-text-field v-model="duration" label="Ano" type="text" required></v-text-field>
        <v-text-field v-model="propriedade" label="Posição" type="text" required></v-text-field>
        <v-text-field v-model="language" label="Língua Oficial" type="text" required></v-text-field>
        
        <v-text-field v-model="tutor" label="Cliente" type="text" required></v-text-field>        
        <v-text-field v-model="localcurso" label="País" type="text" required></v-text-field>
        <v-text-field v-model="publico" label="Público-alvo" type="text" required></v-text-field>
        <SelectGraphics v-model="productCategory" />

        <h4>Adicionar Secções do projecto</h4>
        <v-divider class="my-3"></v-divider>
        <div v-for="(unit, unitIndex) in units" :key="unitIndex">
          <v-text-field v-model="unit.title" label="Title" type="text" required></v-text-field>
         
          <v-textarea v-model="unit.intro" label="Intro" required></v-textarea>
          <!-- Video, Image, and Document Upload Section -->
          <div v-for="(include, includeIndex) in unit.includes" :key="includeIndex">
            <v-text-field v-model="include.name" label="Nome do material ex: videos, ficheiros" type="text" required></v-text-field>
            <v-text-field v-model="include.quantity" label="Quantidade de material ex: 10, 5 etc" type="number" required></v-text-field>        
  
            <!-- Video Upload -->
            <div v-for="(video, videoIndex) in include.videos" :key="videoIndex" class="video-preview">
              <input name="videos" type="file" :ref="'videoFileInput' + includeIndex" @change="handleVideoFileChange($event, unitIndex, includeIndex)" style="display: none" accept="video/*" />
              <div v-if="video.videoFile">
                <video :src="video.videoPreviewUrl" controls class="video-player" />
                <v-text-field v-model="video.caption" label="Caption"></v-text-field>
                <button @click="removeVideo(unitIndex, includeIndex, videoIndex)" class="remove-video-button">Remove Video</button>
              </div>
            </div>
           
            <!-- Image Upload -->
             <div v-if="showImageUpload">
              <div v-for="(image, imageIndex) in include.images" :key="imageIndex" class="image-upload">
              <input type="file" name="images" @change="handleImageChange($event, unitIndex, includeIndex, imageIndex)" accept="image/*" />
              <v-text-field v-model="image.caption" label="Caption"></v-text-field>
              <v-img :src="image.url" class="dialog-image"></v-img>
              <v-btn size="32" icon @click="removeImage(unitIndex, includeIndex, imageIndex)" color="red">
                <v-icon size="xs">mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-btn @click="addImageField(unitIndex, includeIndex)">Adicionar + imagens</v-btn>
             </div>
           
  
            <!-- Document Upload -->
             <div v-if="showdocumentsUpload">
              <div v-for="(document, documentIndex) in include.documents" :key="documentIndex" class="document-upload">
              <input type="file" name="documents" @change="handledocumentsChange($event, unitIndex, includeIndex, documentIndex)" accept=".pdf,.doc,.docx,.xlsx,.ppt" />
              <v-text-field v-model="document.caption" label="Caption"></v-text-field>
              <div v-if="document.type === 'pdf'">
                <iframe :src="document.url" width="100%" height="400px"></iframe>
              </div>
              <v-btn size="32" icon @click="removedocuments(unitIndex, includeIndex, documentIndex)" color="red">
                <v-icon size="xs">mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-btn @click="adddocumentsField(unitIndex, includeIndex)">Add more documents</v-btn>
             </div>
            <!-- Toggle button for videos -->
             <v-btn icon size="small" class="mx-2" variant="outlined" flat @click="triggerVideoFileInput(unitIndex, includeIndex)" color="primary" :disabled="include.videos.length >= 10">
              <v-icon>mdi-video</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">Add up to 10 videos</v-tooltip>
            </v-btn>
            
              <!-- Toggle button for images -->
            <v-btn icon size="small" class="mx-2" variant="outlined" flat @click="toggleImageUpload" color="primary" :disabled="include.images.length >= 10">
              <v-icon>mdi-image</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">Add up to 10 images</v-tooltip>
            </v-btn>
               <!-- Toggle button for documents -->
      <v-btn icon size="small" class="mx-2" variant="outlined" flat @click="toggledocumentsUpload" color="primary" :disabled="include.documents.length >= 10">
        <v-icon>mdi-file-document</v-icon>
        <v-tooltip color="primary" activator="parent" location="end">Add up to 10 documents</v-tooltip>
      </v-btn>
          
  
            <v-btn variant="text" size="small" @click="removeInclude(unitIndex, includeIndex)" color="error" text icon>
              <v-icon>mdi-minus</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">Remover Material</v-tooltip>
            </v-btn>
        
          </div>
          <v-btn variant="outlined" size="small" @click="addNewInclude(unitIndex, includeIndex)" color="primary" icon>
              <v-icon>mdi-plus</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">Adicionar Material</v-tooltip>
            </v-btn>
          <div class="d-flex justify-end">
            <v-btn variant="text" size="small" @click="removeUnit(unitIndex)" color="error" text icon>
              <v-icon>mdi-delete</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">Remover Módulo</v-tooltip>
            </v-btn>
          </div>
          <v-divider class="my-3"></v-divider>
        </div>
        
        <div class="d-flex justify-end">
          <v-btn variant="outlined" @click="addNewUnit" color="primary">
            <v-icon>mdi-plus</v-icon> criar nova secção       
          </v-btn>
        </div>
      
        <v-alert class="my-3" v-if="units.length === 0">Nenhuma secção adicionado ainda</v-alert>
     
        <h3 class="py-3">Mais detalhes do curso</h3>
        <QuillEditor v-model="editorContent" @ready="onEditorReady" />
        <v-btn class="my-10" :loading="loading" type="submit" color="primary">Publicar projecto</v-btn>
      </v-form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import axios from 'axios';
  import { QuillEditor } from '@vueup/vue-quill';
  import BlotFormatter from 'quill-blot-formatter';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import SelectGraphics from '../products/SelectGraphic.vue';


  
  export default {
    name: 'FurnitureForm',
    components: {
      QuillEditor,
      SelectGraphics,
      
 
    },
    setup() {
      const productName = ref('');
      const duration = ref('');
      const showImageUpload = ref(false);
      const showdocumentsUpload = ref(false);    
      const units = ref([]);  
      const propriedade = ref('');
      const tutor = ref('');
      const horas = ref('');
      const localcurso = ref('');
      const publico = ref('');
      const productCategory = ref({
        productcategory: '',
        productModal: '',
        seats: '',
      }); 
        
      const editorContent = ref(null);
      const loading = ref(false);
      const coverPreviewUrl = ref(null);
      const videoErrorMessage = ref('');
      const coverSelectedFile = ref(null);
      const coverUploadProgress = ref(0);
      const coverErrorMessage = ref('');
      const isDragging = ref(false); 
  
     
      let quillInstance = null;
  
      const triggerCoverFileInput = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', handleCoverFileChange);
        input.click();
      };
  
      const handleCoverFileChange = (event) => {
          const file = event.target.files[0];
          processCoverFile(file);
        };
    
        const processCoverFile = (file) => {
          if (file) {
            if (file.size > 5 * 1024 * 1024) {
              coverErrorMessage.value = 'Image must be smaller than 5MB';
            } else {
              coverErrorMessage.value = '';
              coverPreviewUrl.value = URL.createObjectURL(file);
              coverSelectedFile.value = file;
            }
          }
        };
  
      const processVideoFile = (file, unitIndex, videoIndex) => {
      if (file && file.type.startsWith('video/')) {
        if (file.size > 10 * 1024 * 1024) { // Assuming 100MB as the max video size
          videoErrorMessage.value = 'Video must be smaller than 10MB';
        } else {
          videoErrorMessage.value = '';
          const videoPreviewUrl = URL.createObjectURL(file);
          units.value[unitIndex].videos[videoIndex] = { videoFile: file, videoPreviewUrl };
        }
      } else {
        videoErrorMessage.value = 'Invalid file type. Please upload a video.';
      }
  
    };
  
  
      const handleDragOver = () => {
        isDragging.value = true;
      };
  
      const handleDragLeave = () => {
        isDragging.value = false;
      };
  
      const handleDrop = (event) => {
        isDragging.value = false;
        const file = event.dataTransfer.files[0];
        processCoverFile(file);
      };
  
      const handleDropVideo = (event) => {
        isDragging.value = false;
        const file = event.dataTransfer.files[0];
        processVideoFile(file);
      };
  
      const removeVideo = (unitIndex, includeIndex, videoIndex) => {
        units.value[unitIndex].includes[includeIndex].videos.splice(videoIndex, 1);
      };
  
      const handleImageChange = (event, unitIndex, includeIndex, imageIndex) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      units.value[unitIndex].includes[includeIndex].images[imageIndex] = {
        imageFile: file, // Store the actual file object
        url: URL.createObjectURL(file),
        caption: '', // Initialize caption or handle as needed
        filename: file.name,
        path: '', // Initialize path as an empty string
      };
    } else {
      console.error('Invalid image file.');
    }
  };
  
  const handledocumentsChange = (event, unitIndex, includeIndex, documentIndex) => {
    const file = event.target.files[0];
    if (file) {
      units.value[unitIndex].includes[includeIndex].documents[documentIndex] = {
        documentFile: file, // Store the actual file object
        url: URL.createObjectURL(file),
        caption: '', // Initialize caption or handle as needed
        filename: file.name,
        path: '', // Initialize path as an empty string
      };
    } else {
      console.error('Invalid document file.');
    }
  };
  
  const handleVideoFileChange = (event, unitIndex, includeIndex) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      const video = {
        videoFile: file,
        videoPreviewUrl: URL.createObjectURL(file),
        caption: '',
        filename: file.name,
        path: '', // Initialize path as an empty string
      };
      units.value[unitIndex].includes[includeIndex].videos.push(video);
    } else {
      console.error('Invalid video file.');
    }
  };
  
  
  
        // Image Handlers
        const addImageField = (unitIndex, includeIndex) => {
        units.value[unitIndex].includes[includeIndex].images.push({
          url: '',
          caption: '',
        });
      };
       // Document Handlers
       const adddocumentsField = (unitIndex, includeIndex) => {
        units.value[unitIndex].includes[includeIndex].documents.push({
          url: '',
          caption: '',
        });
      };
      const removeImage = (unitIndex, includeIndex, imageIndex) => {
        units.value[unitIndex].includes[includeIndex].images.splice(imageIndex, 1);
      };
      const removedocuments = (unitIndex, includeIndex, documentIndex) => {
        units.value[unitIndex].includes[includeIndex].documents.splice(documentIndex, 1);
      };
  
    // Video Handlers
    const triggerVideoFileInput = (includeIndex, videoIndex) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'video/*';
      input.addEventListener('change', (event) => handleVideoFileChange(event, includeIndex, videoIndex));
      input.click();
    };
  
    
  
    const addNewUnit = () => {
      units.value.push({
        title: '',
        hours: '',
        intro: '',  
        videoUploadProgress: 0,
        includes: [{ name: '', quantity: 0, images:[], videos: [], documents: [] }],
      });
    };
  
      const removeUnit = (unitIndex) => {
        units.value.splice(unitIndex, 1);
      };
  
      const addNewInclude = (unitIndex) => {
    // Ensure units array exists
    if (!Array.isArray(units.value)) {
      units.value = [];
    }
  
    // Ensure the specific unit exists
    if (!units.value[unitIndex]) {
      units.value[unitIndex] = { includes: [] }; // Initialize with includes as an empty array
    }
  
    // Ensure the includes array exists
    if (!Array.isArray(units.value[unitIndex].includes)) {
      units.value[unitIndex].includes = [];
    }
  
    // Add a new include object to the includes array
    units.value[unitIndex].includes.push({ name: '', quantity: 0, videos: [], documents: [], images: [] });
  };
  
  
      const removeInclude = (unitIndex, includeIndex) => {
        units.value[unitIndex].includes.splice(includeIndex, 1);
      };
  
      const onEditorReady = (editorInstance) => {
        quillInstance = editorInstance;
      };
  
   
  
  
  
      const modules = {
        module: BlotFormatter,
        options: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append('image', file);
  
              axios.post('http://localhost:3000/api/upload-image', formData)
                .then((res) => {
                  console.log(res);
                  resolve(res.data.url);
                })
                .catch((err) => {
                  reject('Upload failed');
                  console.error('Error:', err);
                  loading.value = false; // Hide progress bar in case of an error
                });
            });
          },
        },
      };
  
      const toggleImageUpload = () => {
    showImageUpload.value = !showImageUpload.value;
  };
      const toggledocumentsUpload = () => {
    showdocumentsUpload.value = !showdocumentsUpload.value;
  };
  
  const saveProject = async () => {
    loading.value = true; // Show progress bar
    const formData = new FormData();
    formData.append('productName', productName.value);
    formData.append('duration', duration.value);
    formData.append('propriedade', propriedade.value);  
    formData.append('tutor', tutor.value);
    formData.append('horas', horas.value);
    formData.append('localcurso', localcurso.value);
    formData.append('publico', publico.value);
    formData.append('productModal', productCategory.value.productModal);
        formData.append('productcategory', productCategory.value.productcategory);
        formData.append('seats', productCategory.value.seats);      
       
    formData.append('units', JSON.stringify(units.value));
    formData.append('content', JSON.stringify(quillInstance.getContents()));
  
    if (coverSelectedFile.value) {
            formData.append('coverImage', coverSelectedFile.value);
          }
  
    units.value.forEach((unit, unitIndex) => {
      unit.includes.forEach((include, includeIndex) => {
        formData.append(`units[${unitIndex}].includes[${includeIndex}].name`, include.name);
        formData.append(`units[${unitIndex}].includes[${includeIndex}].quantity`, include.quantity);
        
        include.videos.forEach((video, videoIndex) => {
          formData.append('videos', video.videoFile); // Ensure each video is appended correctly
          formData.append(`units[${unitIndex}].includes[${includeIndex}].videos[${videoIndex}].caption`, video.caption);
        });
  
        include.images.forEach((image, imageIndex) => {
          formData.append('images', image.imageFile); // Ensure each image is appended correctly
          formData.append(`units[${unitIndex}].includes[${includeIndex}].images[${imageIndex}].caption`, image.caption);
        });
  
        include.documents.forEach((document, documentIndex) => {
          formData.append('documents', document.documentFile); // Ensure each document is appended correctly
          formData.append(`units[${unitIndex}].includes[${includeIndex}].documents[${documentIndex}].caption`, document.caption);
        });
      });
    });
  
    try {
      const response = await axios.post('http://localhost:3000/api/save-project', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            coverUploadProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        },
      });
  
      console.log('Data saved successfully', response.data);
      clearEditor();
      loading.value = false; // Hide progress bar
    } catch (error) {
      console.error('Error saving data', error);
    } finally {
      loading.value = false;
    }
  };
  
  const clearEditor = () => {
        productName.value = '';     
        units.value = [];
        duration.value = '';
        horas.value = '';
        localcurso.value = '';
        publico.value = '';
        productCategory.value.productModal = '';
        productCategory.value.productcategory = '';
        productCategory.value.seats = '';          
        coverPreviewUrl.value = null;
        coverSelectedFile.value = null;
        coverUploadProgress.value = 0;
        coverErrorMessage.value = '';
        if (quillInstance) {
          quillInstance.setContents([]);
        }
      };
  
      return {
        productName,     
        duration,
        units,     
        toggledocumentsUpload,
        handledocumentsChange,
        propriedade,       
        tutor,
        horas,     
        localcurso,
        publico,
        productCategory,       
        editorContent,
        loading,
        coverPreviewUrl,
        videoErrorMessage,
        coverSelectedFile,
        coverUploadProgress,
        coverErrorMessage,
        isDragging,
    modules,
        showImageUpload,
        showdocumentsUpload,
        toggleImageUpload,
        triggerCoverFileInput,
        handleCoverFileChange,
        processCoverFile,
        processVideoFile,
        handleDragOver,
        handleDragLeave,
        handleDrop,
        handleDropVideo,
        handleImageChange,
        addImageField,
        adddocumentsField,
        removedocuments,
        removeImage,
        triggerVideoFileInput,
        handleVideoFileChange,
        addNewUnit,
        removeVideo, 
        removeUnit,
        addNewInclude,
        removeInclude,
        onEditorReady,
        clearEditor,      
        saveProject,
      };
    },
  };
  </script>
  
  <style scoped>
  .cover-image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 5px;
    border: 2px dashed #ccc;   
    text-align: center;
    width: 100%;
    max-width: 800px;
    height: 210px;
    position: relative;
    transition: background-color 0.3s ease;
  }
  .image-upload, .document-upload {
    margin-top: 10px;
  }
  .cover-image-preview {
    margin-bottom: 10px;
    max-width: 800px;
    width: 100%;
    height: 210px;
  }
  .furniture-form{
    padding: 36px;
  }
  .cover-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .video-preview {
    width: 350px;
    max-height: 600px;
    max-width: 100%;
    margin: 5px;
    
  }
  .video-upload{
    display: flex;
    flex-wrap: wrap;
  }
  .video-player {
    width: 100%;
    height: auto;
  }
  .remove-video-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .remove-video-button:hover {
    background-color: #c62828;
  }
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .image-upload {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .uploaded-image {
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
  }
  
  .dialog-image {
    max-width: 100px;
  }
  </style>
  