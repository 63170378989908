<template>
    <div id="app">
      <GraphicProducts :items="imageData" />
    </div>
  </template>
  
  <script>
  import GraphicProducts from "@/components/products/GraphicProducts.vue";

  export default {
    components: {
      GraphicProducts,
    },
    data() {
      return {
        imageData: [], // Array to store data retrieved from the backend
      };
    },
  };
  </script>
  <style scoped></style>
  