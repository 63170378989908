<template>
    <v-data-table
     v-model="selected"
      :headers="headers"
      :items="desserts"
      :sort-by="[{ key: 'name', order: 'asc' }]"
      show-select
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Meus contactos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn class="mb-2" color="primary" dark v-bind="props">
               Adicionar subscrito
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.name" label="Primeiro nome"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.lastname" label="Ultimo nome"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.contact" label="Contacto"></v-text-field>
                    </v-col>
                   
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.email" label="Email" type="email"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field hide-details v-model="editedItem.url" label="Website" rows="3"></v-text-field>
                    </v-col>
                  
                    <v-col cols="12" md="12" sm="6">
                      <v-textarea hide-details v-model="editedItem.description" label="Descrição" rows="3"></v-textarea>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">Cancelar</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza que pretende apagar este subscrito?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">Sim</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </template>
<script>
export default {
  data: () => ({
    dialog: false,
    selected:[],
    dialogDelete: false,
    headers: [
      { title: 'First Name', key: 'name' },    
      { title: 'Last name', key: 'lastname' },    
      { title: 'Contact', key: 'contact' },   
      { title: 'Email Address', key: 'email' },   
      { title: 'Description', key: 'description' },
      { title: 'Website', key: 'url' },
      { title: 'Actions', key: 'actions', sortable: false },
    ],
    desserts: [], // This should be updated with real data
    editedIndex: -1,
    editedItem: {
      name: '',   
      lastname: '',   
      contact: '',   
      email: '',   
      description: '',
      url: '',
    },
    defaultItem: {
      name: '',  
      lastname: '',  
      contact: '',
      email: '',
      description: '',
      url: '',
    },

  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Adicionar Subsbribers' : 'Editar Subscribers'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    async initialize() {
      try {
        const response = await fetch('https://server.matapa.org/api/subscribers');
        if (response.ok) {
          this.desserts = await response.json();
        } else {
          console.error('Failed to fetch subscribers');
        }
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      }
    },

    async editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        const response = await fetch(`https://server.matapa.org/api/subscribers/${this.editedItem._id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          this.desserts.splice(this.editedIndex, 1);
          this.closeDelete();
        } else {
          console.error('Failed to delete item');
        }
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
  if (this.editedIndex > -1) {
    // Update existing item
    try {
      const response = await fetch(`https://server.matapa.org/api/subscribers/${this.editedItem._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.editedItem),
      });
      if (response.ok) {
        const updatedItem = await response.json();
        Object.assign(this.desserts[this.editedIndex], updatedItem);
        this.close();
      } else {
        console.error('Failed to update item:', await response.text());
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  } else {
    // Create new item
    try {
      const response = await fetch('https://server.matapa.org/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.editedItem),
      });
      if (response.ok) {
        try {
          const newItem = await response.json();
          this.desserts.push(newItem);
          this.close();
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
        }
      } else {
        console.error('Failed to create item:', await response.text());
      }
    } catch (error) {
      console.error('Error creating item:', error);
    }
  }
},
  },
}
</script>