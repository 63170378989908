// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
// import { createVuetify } from "vuetify"; // Import createVuetify from 'vuetify'
import vuetify from "./plugins/vuetify";
import "vuetify/styles"; // Import Vuetify styles
import { loadFonts } from "./plugins/webfontloader";
import router from "../router";
import axios from './axios'; // import your axios configuration

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
loadFonts();

createApp(App)
  .use(vuetify)
  .use(router) // Use the Vue Router plugin
  .use(axios) // Use the Vue Router plugin
  // .use(createVuetify()) // Use the Vue Router plugin
  .mount("#app");
